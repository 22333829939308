import React, { useState } from 'react'
import { Form } from "react-bootstrap"


export default function MesureTemperatura(props) {
    const valorTemperaturaEnEspacios =  () =>{
        const espacioActual = props.espacios.filter((el) => el.id === props.identificador)
        if ( espacioActual[0].temperatura === 0 ) {
            return ""
        }else {
            return espacioActual[0].temperatura
        }
    }
    const [rangeValue, setRangeValue] = useState(valorTemperaturaEnEspacios());
    console.log(props.espacios)
    return (
        <>
                <div className="mesure-container">
                    <small className="metros-small-label">{props.metros} m²</small>
                    <h3>{props.text}</h3>
                    <div className="mesure-result-container"><strong>{rangeValue}</strong><small> °C</small></div>
                    <Form>
                        <Form.Label>Temperatura</Form.Label>
                        <Form.Range 
                            id="1" 
                            min="-5" 
                            max="50" 
                            step="0.5"
                            onChange={(e) => {
                                setRangeValue(e.target.value);
                                props.espacios.find((o,i)=>{
                                    if(o.id === props.identificador) {
                                        props.espacios[i].temperatura = e.target.value;
                                    }
                                })
                            }}
                        />
                    </Form>
                </div>
        </>
    )
}
