import React, { useState }  from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import Nom from '../pages/Nom'
import Ubicacio from '../pages/Ubicacio'
import Composicio from '../pages/Composicio'
import Humitat from '../pages/Humitat'
import Aire from '../pages/Aire'
import Elmeusemafor from '../pages/Elmeusemafor'
import Semaforperespais from '../pages/Semaforperespais'
import Landing from '../pages/Landing'
import PageNoyFound from '../pages/PageNoyFound'
import NavbarComponent from '../components/NavbarComponent'
import Temperatura from '../pages/Temperatura'
import Login from '../pages/Login'
import DadasExteriors from '../pages/DadasExteriors'
import List from '../pages/List'
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import Footer from '../components/Footer'
import Creditos from '../pages/Creditos'
import ExportData from '../pages/ExportData'


export default function AppRouter() {
    const testData = "luis";
    const [actual, setActual ] = useState("");
    const [actualId, setActualId ] = useState("");
    const [espacios,setEspacios] = useState([]);

    const [info, setInfo] = useState([]);
    const [infoEspais, setInfoEspais] = useState([]);

    const [perfil, setPerfil] = useState({
        id: "",
        nombre: "",
        tipo: "",
        direccion: "",
        cod: "",
        provincia: "",
        ciudad: "",
        fechahora:"",
        humedad: "",
        temperatura: ""
    });

    return (
        // <UserContext.Provider value={{espais, setEspais}}>
        <div className="main-container App">
            <HashRouter>
                <NavbarComponent />
                <div className='content-container'>
                    <Switch>
                        {/* <Route exact path="/login">
                            <Login></Login>
                        </Route> */}
                        <Route exact path="/nom">
                            <Nom perfil={perfil} setPerfil={setPerfil}></Nom>
                        </Route>
                        <Route exact path="/ubicacio">
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                <Ubicacio perfil={perfil} setPerfil={setPerfil}></Ubicacio>
                            </MuiPickersUtilsProvider>
                        </Route>
                        <Route exact path="/exteriors">
                            <DadasExteriors actualId={actualId} setActualId={setActualId} perfil={perfil} setPerfil={setPerfil} actual={actual} setActual={setActual}></DadasExteriors>
                        </Route>
                        <Route exact path="/composicio">
                            <Composicio testData={testData} actual={actual} espacios={espacios} setEspacios={setEspacios}></Composicio>
                        </Route>
                        <Route exact path="/humitat">
                            <Humitat actual={actual} espacios={espacios} setEspacios={setEspacios}></Humitat>
                        </Route>
                        <Route exact path="/temperatura">
                            <Temperatura actual={actual} espacios={espacios} setEspacios={setEspacios}></Temperatura>
                        </Route>
                        <Route exact path="/aire">
                            <Aire actual={actual} espacios={espacios} setEspacios={setEspacios}></Aire>
                        </Route>
                        <Route exact path="/elmeusemafor">
                            <Elmeusemafor actual={actual}></Elmeusemafor>
                        </Route>
                        <Route exact path="/semaforperespais">
                            <Semaforperespais actual={actual} info={info} setInfo={setInfo} infoEspais={infoEspais} setInfoEspais={setInfoEspais}></Semaforperespais>
                        </Route>
                        <Route exact path="/list">
                            <List></List>
                        </Route>
                        <Route exact path="/credits">
                            <Creditos></Creditos>
                        </Route>
                        <Route exact path="/export">
                            <ExportData info={info} infoEspais={infoEspais}></ExportData>
                        </Route>
                        <Route exact path="/">
                            <Landing></Landing>
                        </Route>
                        <Route path="*">
                            <PageNoyFound></PageNoyFound>
                        </Route>
                    </Switch>
                </div>
                <Footer />
            </HashRouter>
        </div>
    )
}
