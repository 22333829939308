import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import UbicationImg from '../img/ubicacion.png'
import { Form, FloatingLabel } from 'react-bootstrap'
import axios from 'axios'
import {DateTimePicker} from '@material-ui/pickers'

export default function Ubicacio(props) {
    const [date, setDate] = useState(new Date());
    const [data, setData] = useState([]);
    const baseurl = "http://inventamosoerramos.com/api/perfil/";
    const handlerChange = e=> {
        const {name, value} = e.target;
        props.setPerfil((prevState)=>({
            ...prevState,
            [name]:value
        }))
    }
    const handlerChangeDateTime = (date)=> {
        props.setPerfil((prevState)=>({
            ...prevState,
            "fechahora":date
        }))
    }

    const peticionGet = async()=> {
        await axios.get(baseurl)
        .then(response=>{
            setData(response.data);
        })
    }
    useEffect(()=>{
        peticionGet();
        // var selectTipus = document.getElementById("form-select-tipus");
    },[]);
    console.log(JSON.stringify(props.perfil));
    console.log(data);
    return (
        <div className="ubicacio-container">
            <h1 className="title">On estem ubicades?</h1>
            <Form>
            {/* <Button variant="primary"><img src={UbicationImg} alt="vinyeta"></img>Compartir ubicació</Button> */}
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <FloatingLabel
                            controlId="floatingInput"
                            label="Codi postal"
                            className="mb-3"
                        >
                            <Form.Control type="text" placeholder="08041" name="cod" onChange={handlerChange} value={props.perfil.cod}/>
                </FloatingLabel>
            </Form.Group>
            <Form.Group className="dos-col" controlId="exampleForm.ControlTextarea1">
                <FloatingLabel
                            controlId="floatingInput"
                            label="Província"
                            className="mb-3"
                        >
                            <Form.Control type="text" placeholder="Gerona" name="provincia" onChange={handlerChange} value={props.perfil.provincia}/>
                </FloatingLabel>
                <FloatingLabel
                            controlId="floatingInput"
                            label="Ciutat"
                            className="mb-3"
                        >
                            <Form.Control type="text" placeholder="Gerona" name="ciudad" onChange={handlerChange} value={props.perfil.ciudad}/>
                </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <FloatingLabel
                            controlId="floatingInput"
                            label="Adreça"
                            className="mb-3"
                        >
                            <Form.Control type="text" placeholder="Carrer Xiprer, 48 2-3" name="direccion" onChange={handlerChange} value={props.perfil.direccion}/>
                </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-3 date-time" controlId="exampleForm.ControlInput1">
                <label>Data i hora</label>
                <br/>
                <DateTimePicker value={date} onChange={setDate, handlerChangeDateTime} name="fechahora"></DateTimePicker>
            </Form.Group>
            </Form>
            {/* <Button className="general-button" variant="primary" onClick={()=>peticionPost()}>Guardar</Button>
            <Button className="general-button" variant="primary" onClick={()=>guardar()}>console perfil</Button> */}
            {/* Geolocalización */}

        </div>
    )
}
