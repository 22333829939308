import React, { useState } from 'react';
import './App.css';
import AppRouter from './routers/AppRouter';
import Login from './pages/Login'

function App() {
  const [conectado, setConectado] = useState(true);

  return (
      conectado ? <AppRouter /> : <Login setConectado={setConectado}/>
  );
}

export default App;
