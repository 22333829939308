import React, { useState, useEffect } from 'react'
import iconTemperatura from "../img/temperatura-alta.png"
import { Button } from 'react-bootstrap'
import MesureTemperatura from "../components/MesureTemperatura"
import axios from 'axios'
import toast, { Toaster } from 'react-hot-toast';


export default function Temperatura(props) {
    const notify = (nombre) => toast.success(nombre+' pujats');
    const notifyUpdate = (nombre) => toast.success(nombre+' actualizat');
    const [data, setData] = useState([]);
    const [temperatura, setTemperatura] = useState(0);
    const baseurl = "http://inventamosoerramos.com/api/espais/";
   
    const peticionGetEspais = async()=> {
        await axios.get(baseurl+"?perfil="+props.actual)
        .then(response=>{
            setData(response.data);
            console.log(data)
        })
    }
    const peticionPost = async(espacio)=> {        
        var f = new FormData();
        f.append("nombre", espacio.nombre);
        f.append("metros", espacio.metros);
        f.append("tiempo", espacio.tiempo);
        f.append("humitat", espacio.humedad);
        f.append("temperatura", espacio.temperatura);
        f.append("aire", espacio.aire);
        f.append("identificador", espacio.id);
        f.append("perfil", espacio.perfil);
        f.append("tipo", espacio.type);
        f.append("alias", espacio.alias);
        f.append("METHOD", "POST");
        await axios.post(baseurl, f)
        .then(response=>{
            console.log("Espais pujats!");
            notify(espacio.nombre);
        })
    }
    const peticionPut = async(espacio, id)=> {        
        var f = new FormData();
        f.append("nombre", espacio.nombre);
        f.append("metros", espacio.metros);
        f.append("tiempo", espacio.tiempo);
        f.append("humitat", espacio.humedad);
        f.append("temperatura", espacio.temperatura);
        f.append("aire", espacio.aire);
        f.append("identificador", espacio.id);
        f.append("perfil", espacio.perfil);
        f.append("tipo", espacio.type);
        f.append("alias", espacio.alias);
        f.append("METHOD", "PUT");
        await axios.post(baseurl, f, {params: {id: id}})
        .then(response=>{
            console.log("espacio actualizado")
            notifyUpdate(espacio.nombre)
        })
    }
    const handlerSave = () => {
        peticionGetEspais();
        console.log(data.length)
        if (data.length === 0) {
            // POST toda la data
            console.log("POST TODO")
            props.espacios.map(espacio => {
                peticionPost(espacio)
            });
        }else {
            // Ya hay algun espacios con ese perfil
            // buscar cada espacio
            console.log("Habia un espacio con ese perfil. Hay que verificar")
            props.espacios.map(espacio => {
                // si el espacio está PUT hago un array con todas las coincidencias en data
                var resultado = data.filter((elemento, index, array) => {
                    if (elemento.identificador === espacio.id) {
                        return true
                    }else {
                        return false
                    }
                })
                if (resultado.length === 0) {
                        // el espacio no está en data -> hago POST
                        console.log("El espacio no está en data")
                        peticionPost(espacio)
                }else{
                        // el espacio si está -> PUT en todos los ids
                        resultado.map((e) => {
                        peticionPut(espacio, e.id);
                    })
                }
            });
        }
    }

    useEffect(()=>{
        peticionGetEspais();
    },[]);
    console.log(props.actual)
    return (
        <>
            <Toaster />
            <h1 className="title">Mesurem i registrem la temperatura <img className="title-icon" src={iconTemperatura} alt="vinyeta"/></h1>
            <div className="mesure-grid-container">
                {props.espacios.map(espacio => (
                    <MesureTemperatura metros={espacio.metros} espacios={props.espacios} identificador={espacio.id} key={espacio.id} text={espacio.nombre} temperatura={temperatura} setTemperatura={setTemperatura}/>
                ))}
            </div>
            <Button className="general-button" variant="primary" type="submit" onClick={()=>handlerSave()}>Guardar</Button>
        </>
    )
}
