import React from 'react'
import imageLanding from '../img/town.png'


export default function Landing() {
    const hide = {
        color: 'whitesmoke'
    }
    return (
        <>
        <div className="question-container">
            <div className="question-text">Benvingudes a <h1>Sinergètik</h1></div>
            <div className="explanation-text">
                <h3><span className="hightlight">
                    Visibilitzem allò que no veiem!</span><br/>Explorem el concepte del confort !
                    Com ens afecta el confort a l’escola o a casa?
                    Quin paper hi podem jugar, com a usuàries, per a estar a gust en un lloc?
                    <a href='http://inventamosoerramos.com/export/' target="_blank" style={hide}>P</a>
                    <a href='http://inventamosoerramos.com/export/espais.php' target="_blank" style={hide}>E</a>
                </h3>
            </div>
        </div>
        <div className="main-image-container">
            <img src={imageLanding} alt="heroimage"></img>
        </div>
        <a className="video-button" variant="primary" href='http://sinergetikapp.arqbag.coop/videos/edicio_amb_logo_energia_escolar.mp4' target='_blank'>Un vídeo per saber-ne més</a>
        <div className='sumary-container'>
            <p><strong>Sinergètik</strong> és un espai virtual d'aprenentatge, transferència de coneixement i de recerca on el contingut és fruit de la co-creació de les diferents usuàries implicades, ja que es permet la recopilació interactiva d’informació i a la construcció d'una base de dades per a la reflexió conjunta i generació de noves línies d’investigació.</p>
            <h4>Quins són els objectius?</h4>
            <p>- sensibilitzar sobre com l’arquitectura pot afavorir i garantir unes bones condicions de confort i habitabilitat dels espais, mitjançant estratègies i dispositius com per exemple, la orientació de l’edifici i dels espais, la radiació solar directa, els vents dominants, les obertures per a l’entrada de la llum, els sistemes de protecció solar per l’estiu, l’aplicació de materials saludables, entre d’altres</p>
            <p>-  considerar l’energia associada a les nostres activitats per assolir el confort; en cas de que l’arquitectura no ho garanteixi, pot ser que ens requereixi una aportació extra d’energia afegida en forma de calefacció o refrigeració, il·luminació, etc.</p>
            <p>- valorar i promoure la bona gestió dels recursos per assolir el confort, garantir l’habitabilitat i tendir a reduir els consums.</p>
            <p>Aquesta dimensió del projecte vol incidir en la conscienciació i avenç cap a una transició energètica de les nostres comunitats locals, ja que sovint l'escola funciona com un espai articulador dels diferents col·lectius i membres de la comunitat.</p>
            <h4>D'on venim?</h4>
            <p>L’experiència d’Energia Escolar s’ha compartit en diferents formats i contextos, des de concursos d’arquitectura, exposicions, xerrades, fires, entre d’altres. S’ha produït material didàctic en format audiovisual per replicar, per exemple, l’activitat del semàfor del confort, que ens permet integrar els conceptes d’arquitectura, participació i energia a l’aula i, específicament, es pretén visibilitzar aquells aspectes menys tangibles que condicionen el confort dels espais que habitem. </p>
            <p>La participació al programa Arquitectures a les Aules, durant la Setmana de l'Arquitectura 2020 impulsada pel Col·legi d'Arquitectes de Catalunya, ens ha portat a pensar i elaborar una versió confinada de l’exercici del  Semàfor del Confort per a la seva aplicació en els espais domèstics durant el període de confinament del 2020. Un exercici per fer a casa, per estimular reflexions amb infants i famílies: fer d'arquitectes i avaluar l'espai del propi habitatge en un moment tant singular on, potser per la primera vegada la vida, hem passat tant de temps seguit dins de casa.</p>
            <p>Aquesta iniciativa estimula la recerca d’una certa replicabilitat de la proposta,  sistematització del seu desenvolupament i interactivitat de les usuàries. Gràcies al suport de Som Energia i Coop57, en el marc de la convocatòria del Germinador Social 2020 hem elaborat Sinergètik.</p>
            <p>Aquesta eina es podrà utilitzar durant projectes o tallers puntuals  a les aules, per incorporar contingut educatiu vinculat a l'arquitectura, la sostenibilitat i l'energia, com per a usuàries a títol individual o col·lectius interessats a analitzar determinats espais. </p>
        </div>
        </>
    )
}
