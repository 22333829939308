import React, { useState, useEffect } from 'react'
import axios from 'axios'
import '../css/semafor.css'
import imgAnalisis from "../img/analisis.png"


export default function Elmeusemafor(props) {
    console.log("perfil actual: "+props.actual)
    const [data, setData] = useState([]);
    const [circle1, setCircle1] = useState("")
    const [circle2, setCircle2] = useState("")
    const [circle3, setCircle3] = useState("")
    const baseurl = "http://inventamosoerramos.com/api/espais/";
    const aire = [];
    const humedad = [];
    const temperatura = [];
    const aireInteriorEdifici = [];
    const humedadInteriorEdifici = [];
    const temperaturaInteriorEdifici = [];
    
    // const calidadAire = (arrayAire) => {
    //     arrayColores = []
    //     var verdes = 0;
    //     var countVerdes = 0;
    //     var amarillos = 0;
    //     var countAmarillos = 0;
    //     var rojos = 0;
    //     var countRojos = 0;
    //     arrayAire.map((elem) => {
    //         if (elem > 400 && elem <= 800) { 
    //             countVerdes++
    //         }
    //         if (elem > 800 && elem <= 850) {  
    //             countAmarillos++
    //         }
    //         if (elem > 850) {  
    //             countRojos++
    //         }   
    //     })
    //     arrayColores.push(countVerdes)
    //     arrayColores.push(countAmarillos)
    //     arrayColores.push(countRojos)
    //     var elMaximo = Math.max(countVerdes,countAmarillos,countRojos);
    //     if (elMaximo = ) {

    //     }
    //     return "amarillo"
    // }
    // 
    const peticionGetEspais = async()=> {
        await axios.get(baseurl+"?perfil="+props.actual)
        .then(response=>{
            setData(response.data);
            console.log(data)
            console.log(calculInteriorEdifici());
            // calculSemaforGeneral();
        })
    }
    // const calculSemaforGeneral = () => {
    //     data.map((elem) => {
    //         aire.push(elem.aire)
    //         humedad.push(elem.humitat)
    //         temperatura.push(elem.temperatura)
    //     })
    //     console.log(aire)
    //     // console.log(temperatura)
    //     // console.log(humedad)
    //     console.log(calidadAire(aire));
    // }
    const calculInteriorEdifici = () => {
        // filtrar la data
        var dataFiltradaInterior = data.filter((elemento, index, array)=> {
            if (elemento.tipo === "1") {
                return true
            }else {
                return false
            }
        })
        console.log(dataFiltradaInterior);
    }
    useEffect(()=>{
            if (props.actual != "") {
                peticionGetEspais();
            }else {
                alert("no hay perfil seleccionado")
            }
            setCircle1("grey")
            setCircle2("red blink")
            setCircle3("grey")
    },[]);
    return (
        <div>
            <h1 className="title">Com estem? <img className="title-icon" src={imgAnalisis} alt="icon"/></h1>
            <div className="semafors-container">
                <div className="semafor-frame general">
                    <h4>Semàfor general</h4>
                    <div className="circle-frame-big">
                        <div className="circle-border-big-grey"><div className={circle1 + " circle big"}></div></div>
                        <div className="circle-border-big-grey"><div className={circle2 + " circle big"}></div></div>
                        <div className="circle-border-big-grey"><div className={circle3 + " circle big"}></div></div>
                    </div>
                </div>
                <div className="espais-semafor-container">
                    <div className="semafor-frame">
                        <h5>Interior de l'edifici</h5>
                        <div className="circle-frame">
                            <div className="circle-border"><div className="circle small green"></div></div>
                            <div className="circle-border"><div className="circle small grey"></div></div>
                            <div className="circle-border"><div className="circle small grey"></div></div>
                        </div>
                    </div>
                    <div className="semafor-frame">
                        <h5>Exterior de l'edifici</h5>
                        <div className="circle-frame">
                            <div className="circle-border"><div className="circle small green"></div></div>
                            <div className="circle-border"><div className="circle small grey"></div></div>
                            <div className="circle-border"><div className="circle small grey"></div></div>
                        </div>
                    </div>
                    <div className="semafor-frame">
                        <h5>Fora del centre (interior)</h5>
                        <div className="circle-frame">
                            <div className="circle-border"><div className="circle small grey"></div></div>
                            <div className="circle-border"><div className="circle small grey"></div></div>
                            <div className="circle-border"><div className="circle small red blink"></div></div>
                        </div>
                    </div>
                    <div className="semafor-frame">
                        <h5>Fora del centre (exterior)</h5>
                        <div className="circle-frame">
                            <div className="circle-border"><div className="circle small grey"></div></div>
                            <div className="circle-border"><div className="circle small yellow blink"></div></div>
                            <div className="circle-border"><div className="circle small grey"></div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
