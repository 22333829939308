import React, { useState } from 'react'
import Logo from '../img/logo.png'
import "../css/login.css"
import HouseLines from "../img/white-house.png"
import axios from 'axios'
import { InputGroup, Button, FormControl, Form, FloatingLabel } from 'react-bootstrap'


export default function Login() {
    const [data, setData] = useState([]);
    const baseurl = "http://inventamosoerramos.com/api/login/";
    const [usuari, setUsuari] = useState("");
    const [clau, setClau] = useState("");

    const peticionGetUser = async()=> {

        await axios.get(baseurl+"?clave="+ clau)
        .then(response=>{
            setData(response.data);
            console.log(data)
        })

    }
    
    const haldlerLogin = () => {

        const data = {
            'usuari': usuari,
            'clau': clau 
        }

        console.log(data)
    }
    return (
        <div>
            <div className="login-form-container">
                <div className="login-form">
                    <div className="login-logo-container"><img className="logo" src={Logo} alt="vinyeta"/><strong>Sinergetik</strong></div>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Usuari"
                        className="mb-3 login-form-input"
                    >
                    <Form.Control type="email" placeholder="name@example.com" onChange={(e) => {setUsuari(e.target.value)}}/>
                    </FloatingLabel>
                    <FloatingLabel controlId="floatingPassword" label="Clau">
                        <Form.Control type="password" placeholder="Password" onChange={(e) => {setClau(e.target.value)}}/>
                    </FloatingLabel>
                    <Button className="login-button" onClick={haldlerLogin}>
                        Entrar
                    </Button>
                </div>
                <img className="bg-login-img" alt="draw" src={HouseLines} />
            </div>
        </div>
    )
}
