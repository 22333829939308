import React, { useState, useEffect } from 'react'
import { InputGroup, Button, FormControl, Form, Dropdown } from 'react-bootstrap'
import nomVignette from '../img/nom-vignette.png'
import axios from 'axios'

export default function Nom(props) {
    const [data, setData] = useState([]);
    const baseurl = "http://inventamosoerramos.com/api/perfil/";

    const peticionGet = async()=> {
            await axios.get(baseurl)
            .then(response=>{
                setData(response.data);
            })
    }
    const handlerChange = e=> {
        const {name, value} = e.target;
        props.setPerfil((prevState)=>({
            ...prevState,
            [name]:value
        }))
    }
    const guardar = () => {
        console.log(props.perfil);
    }
    useEffect(()=>{
        // peticionGet();
        // var selectTipus = document.getElementById("form-select-tipus");
    },[]);
    console.log(JSON.stringify(props.perfil));
    return (
        <div className="question-container">
            <img className="img-vignette" src={nomVignette} alt="vinyeta"/>
           {/* <div className ="question-text"><h1>Registrar un nom o alias per l'análisis</h1></div> */}
           <div className="explanation-text form-margin">
                <h3>
                    <span className="hightlight">Creem un perfil per obtenir el semàfor del confort!</span><br/>
                    Registrar un nom o àlies i seleccionar el lloc de l'anàlisi!
                </h3>
            </div>
            <InputGroup className="mb-3 nom-group">
                <FormControl
                placeholder="Nom"
                aria-label="Nom"
                aria-describedby="basic-addon2"
                value={props.perfil.nombre}
                onChange={handlerChange}
                name="nombre"
                >
                </FormControl>
                <Form.Select id="form-select-tipus" aria-label="Default select example" name="tipo" onChange={handlerChange} value={props.perfil.tipo}>
                    <option>Tipus</option>
                    <option value="centre">Centre</option>
                    <option value="habitatge">Habitatge</option>
                </Form.Select>
            </InputGroup>
            {/* <Button className="general-button" variant="primary" onClick={()=>peticionPost()}>Guardar</Button>
            <Button className="general-button" variant="primary" onClick={()=>guardar()}>console perfil</Button> */}

        </div>
    )
}
