import React from 'react'
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/nav.css'
import { Link } from 'react-router-dom'


export default function NavbarComponent() {
    return (
        <div className="nav-container">

            <Navbar className="nav-bar-style" expand="lg">
            <Container>
                <Navbar.Brand href="/#">
                <img
                alt="sinergetik"
                src="/logo.png"
                width="30"
                height="30"
                className="d-inline-block align-top"
                />
                <strong>Sinergètik</strong>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <NavDropdown title="Perfil" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#/nom">Nom</NavDropdown.Item>
                        <NavDropdown.Item href="#/ubicacio">Ubicació</NavDropdown.Item>
                        <NavDropdown.Item href="#/exteriors">Dades</NavDropdown.Item>
                    </NavDropdown>
                    <Link className="nav-link" role="button" tab-index="0" to="/composicio">Anàlisi</Link>
                    <NavDropdown title="Paràmetres" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#/humitat">Humitat</NavDropdown.Item>
                        <NavDropdown.Item href="#/temperatura">Temperatura</NavDropdown.Item>
                        <NavDropdown.Item href="#/aire">Qualitat de l'aire</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
                <Nav>
                    {/* <Nav.Link><Link to="/elmeusemafor">El meu semàfor</Link></Nav.Link> */}
                    <Nav.Link><Link to="/semaforperespais">Semàfor del confort</Link></Nav.Link>
                </Nav>
                </Navbar.Collapse>
            </Container>
            </Navbar>
            
        </div>
    )
}
