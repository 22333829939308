import React, { useState } from 'react'
import { Form } from 'react-bootstrap'

export default function MesureHumitat(props) {
    const valorHumedadEnEspacios =  () =>{
        const espacioActual = props.espacios.filter((el) => el.id === props.identificador)
        if ( espacioActual[0].humedad === 0 ) {
            return ""
        }else {
            return espacioActual[0].humedad
        }
    }
    const [rangeValue, setRangeValue] = useState(valorHumedadEnEspacios());
    console.log(props.espacios)
    return (
        <>
                <div className="mesure-container">
                    <small className="metros-small-label">{props.metros} m²</small><br/>
                    <small className="metros-small-label">{props.alias}</small>
                    <h3>{props.text}</h3>
                    <div className="mesure-result-container"><strong>{rangeValue}</strong><small> %</small></div>
                    <Form>
                        <Form.Label>Humitat</Form.Label>
                        <Form.Range 
                            id="1" 
                            min="0" 
                            max="100" 
                            onChange={(e) => {
                                setRangeValue(e.target.value);
                                props.espacios.find((o,i)=>{
                                    if(o.id === props.identificador) {
                                        props.espacios[i].humedad = e.target.value;
                                    }
                                })
                            }}
                        />
                    </Form>
                </div>
        </>
    )
}
