import React from 'react'

export default function creditos() {
  return (
    <>
      <div className="question-container">
      <h2 className="title">Producció</h2>
        <div className='credits-container'>
          <div className='credits-item'>
            <div className='credits-nombre'>arqbag</div>
            <div className='credits-title'>Productor general</div>
          </div>
          <div className='credits-item'>
            <div className='credits-nombre'>Nicola Cruz</div>
            <div className='credits-title'>Coordinador de proyecto</div>
          </div>
          <div className='credits-item'>
            <div className='credits-nombre'>Luis Soria</div>
            <div className='credits-title'>Desarrollador</div>
          </div>
        </div>
      </div>
    </>
  )
}
