import React, { useState } from 'react'
import '../App.css';
import { Form } from 'react-bootstrap';
import cuinaIcon from '../img/cocina.png'
import jardinIcon from '../img/jardin.png'
import deportivoIcon from '../img/campo-deportivo.png'
import bibliotecaIcon from '../img/biblioteca.png'
import { v4 as uuidv4 } from 'uuid';


export default function FormSelectEspais({actual, text, input, setInput, espais, setEspais, espacios, setEspacios, type, tipo, setTipo }) {
    
    // var [tipo, setTipo] = useState("");
    // type es la etiqueta de cada una de las listas
    const [key, setKey] = useState(uuidv4());
    // console.log(actual);
    const handleChange = e => {
        // esto solo sirve para cambiar el valor visual del select
        e.preventDefault();
        setInput(e.target.value);
    };
    
    const handleSubmit = (e) => {     
        e.preventDefault();
   
        if ( input !== "" && actual !== "" ) {
            var etiqueta = e.target.getAttribute('lugar'); 
            setKey(uuidv4());
            setEspais([
                ...espais,
                {perfil: actual, nombre: input, id: key, metros:"0", tiempo:"0", type: etiqueta, humedad: "0", temperatura:"0", aire:"0" }
            ]);
            setEspacios([
                ...espacios,
                {perfil: actual, nombre: input, alias:"", id: key, metros:"", tiempo:"", type: etiqueta, humedad: "0", temperatura:"0", aire:"0" }
            ]);
        }
        if ( actual === "" ) {
            alert("No hay un perfil seleccionado");
        }
    };
    return (
        <div>
                { text === "Espais interiors de l'edifici" &&
                    <Form className="form-container" onSubmit={handleSubmit}>
                        <img className="icon-select" src={cuinaIcon} alt="icon"/>
                        <label>
                        <p>{text}</p> 
                        </label>
                        <div className="select-container">
                            <Form.Select  aria-label="Default select example" value={input} onChange={handleChange}>
                                <option value="">Llista d'espais</option>
                                <option value="Dormitori">Dormitori</option>
                                <option value="Cuina">Cuina</option>
                                <option value="Escales">Escales</option>
                                <option value="Aula">Aula</option>
                                <option value="Menjador">Menjador</option>
                                <option value="Passadís">Passadís</option>
                                <option value="Porteria">Porteria</option>
                                <option value="Gymnàs">Gimnàs</option>
                                <option value="Altre">Altre</option>
                            </Form.Select>
                            <a className="add-input" onClick={handleSubmit} value="+" lugar={1}>+</a>
                        </div>
                    </Form>
                }
                { text === "Espais exteriors de l'edifici" &&
                    <Form className="form-container" onSubmit={handleSubmit}>
                        <img className="icon-select" src={jardinIcon} alt="icon"/>
                        <label>
                        <p>{text}</p> 
                        </label>
                        <div className="select-container">
                            <Form.Select className="select-style" aria-label="Default select example" value={input} onChange={handleChange}>
                                <option value="">Llista d'espais</option>
                                <option value="Plaça">Plaça</option>
                                <option value="Jardí">Jardí</option>
                                <option value="Accés">Accés</option>
                                <option value="Terrat">Terrat</option>
                                <option value="Terrassa">Terrassa</option>
                                <option value="Altre">Altre</option>
                            </Form.Select>
                            <a className="add-input" onClick={handleSubmit} value="+" lugar={2}>+</a>
                        </div>
                    </Form>                } 
                { text === "Espais fora del centre (interiors)" &&
                    <Form className="form-container" onSubmit={handleSubmit}>
                        <img className="icon-select" src={bibliotecaIcon} alt="icon"/>
                        <label>
                        <p>{text}</p> 
                        </label>
                        <div className="select-container">
                            <Form.Select className="select-style" aria-label="Default select example" value={input} onChange={handleChange}>
                                <option value="">Llista d'espais</option>
                                <option value="Biblioteca">Biblioteca</option>
                                <option value="Centre civic">Centre cívic</option>
                                <option value="Museu">Museu</option>
                                <option value="Local">Local</option>
                                <option value="Poliesportiu">Poliesportiu</option>
                                <option value="Centre educatiu">Centre educatiu</option>
                                <option value="Casal d'avis">Casal d'avis</option>
                                <option value="Equip. municipal">Equip. municipal</option>
                                <option value="Equip. privat">Equip. privat</option>
                                <option value="Altre">Altre</option>
                            </Form.Select>
                            <a className="add-input" onClick={handleSubmit} value="+" lugar={3}>+</a>
                        </div>
                    </Form>                 
                } 
                { text === "Espais fora del centre (exteriors)" &&
                    <Form className="form-container" onSubmit={handleSubmit}>
                        <img className="icon-select" src={deportivoIcon} alt="icon"/>
                        <label>
                        <p>{text}</p> 
                        </label>
                        <div className="select-container">
                            <Form.Select className="select-style" aria-label="Default select example" value={input} onChange={handleChange}>
                                <option value="">Llista d'espais</option>
                                <option value="Plaça">Plaça</option>
                                <option value="Carrer">Carrer</option>
                                <option value="Bosc">Bosc</option>
                                <option value="Parc">Parc</option>
                                <option value="Platja">Platja</option>
                                <option value="Camp">Camp</option>
                                <option value="Equip. municipal">Equip. municipal</option>
                                <option value="Equip. privat">Equip. privat</option>
                                <option value="Altre">Altre</option>
                            </Form.Select>
                            <a className="add-input" onClick={handleSubmit} value="+" lugar={4}>+</a>
                        </div>
                    </Form>                 
                } 
        </div>
    )
}
