import React, { useState, useEffect } from 'react'
import '../App.css';
import EspaiSelector from '../components/EspaiSelector';
import axios from 'axios'



export default function Composicio(props) {
    const baseurl = "http://inventamosoerramos.com/api/espais/";
    const [data, setData] = useState([]);
    const [tipo, setTipo] = useState("");
    // console.log(tipo)
    const handlerChange = e=> {
        const {name, value} = e.target;
    }
    const peticionGet = async()=> {
        await axios.get(baseurl)
        .then(response=>{
            setData(response.data);
        })
    }
    useEffect(()=>{
        peticionGet();
    },[]);
    return (
        <div>
            <div className ="title question-text"><h1>Analitzem el que ens envolta</h1></div>
            <div className="instructions-text">
                <p>
                Seleccionar els espais que componen la zona que es vol estudiar. <span className="hightlight"> 
                    Afegir i completar les dades de superfície i temps d'ús.
                    Anomenar els espais repetits o els que requereixen major explicació.
                </span>
                </p>
            </div>
            <div className="espais-selector-container">
                <EspaiSelector tipo={tipo} setTipo={setTipo} type={1} actual={props.actual} espacios={props.espacios} setEspacios={props.setEspacios} text="Espais interiors de l'edifici" />
                <EspaiSelector tipo={tipo} setTipo={setTipo} type={2} actual={props.actual} espacios={props.espacios} setEspacios={props.setEspacios} text="Espais exteriors de l'edifici" />
                <EspaiSelector tipo={tipo} setTipo={setTipo} type={3} actual={props.actual} espacios={props.espacios} setEspacios={props.setEspacios} text="Espais fora del centre (interiors)" />
                <EspaiSelector tipo={tipo} setTipo={setTipo} type={4} actual={props.actual} espacios={props.espacios} setEspacios={props.setEspacios} text="Espais fora del centre (exteriors)" />
            </div>
            {/* <Button className="general-button" variant="primary" >Guardar</Button> */}
        </div>
    )
}
