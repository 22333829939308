import React, { useState } from 'react'
import Espai from './Espai'

export default function ListArea(props) {
    const filterItems = query => {
        return props.espacios.filter((el) =>
          el.type.indexOf(query) > -1
        );
    }
    // console.log(props.espacios)
    // console.log(props.type)
    return (
        <div>
                <div className="list-container">
                        <div className="list-title">Seleccionats</div>
                        <div className="items-container">
                            {/* {props.espais.map(espai => (
                                // con el id puedo agregar los campos de metros y tiempo | Done
                                // El segundo problema es que la lista de espacios se rendea a partir de espais
                                // y la lista de espacios a partir
                                <Espai key={espai.id} text={espai.nombre} espais={props.espais} setEspais={props.setEspais} espai={espai} espacios={props.espacios} setEspacios={props.setEspacios}/>
                                ))} */}
        
                            {/* {props.espacios.map(espacio => {
                                    <Espai key={espacio.id} text={espacio.nombre} espais={props.espais} setEspais={props.setEspais} espai={props.espai} espacios={props.espacios} setEspacios={props.setEspacios}/>
                            })} */}
                            {props.espacios.filter(e => e.type.includes(""+ props.type)).map(element => (
                                    // <li>
                                    // {element.nombre}<br/>
                                    // {element.metros}
                                    // </li>
                                    
                                    <Espai actual={props.actual} metros={props.metros} setMetros={props.setMetros} tiempo={props.tiempo} setTiempo={props.setTiempo}  key={element.id} text={element.nombre} espais={props.espais} setEspais={props.setEspais} espai={element.id} espacios={props.espacios} setEspacios={props.setEspacios}/>

                            ))}

                        </div>
                    </div>
        </div>
    )
}
