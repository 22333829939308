import React from 'react'
import { Table } from 'react-bootstrap';
import ReactExport from 'react-data-export'
import { Button } from 'react-bootstrap'

const ExcelFile = ReactExport.ExcelFile;
const ExcelFileDos = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelSheetDos = ReactExport.ExcelFile.ExcelSheet;

export default function ExportData(props) {

  const DataSet = [
      {
          columns: [
              {title: "Nom"},
              {title: "Tipus"},
              {title: "Direcció"},
              {title: "Ciutat"},
              {title: "Província"},
              {title: "CP"},
              {title: "Humitat exterior"},
              {title: "Temperatura exterior"},
              {title: "Dada i Hora"},
          ],
          data: props.info.map(data => [
              {value: data.nombre},
              {value: data.tipo},
              {value: data.direccion},
              {value: data.ciudad},
              {value: data.provincia},
              {value: data.cod},
              {value: data.humedad},
              {value: data.temperatura},
              {value: data.fechahora}
            ])
      },
      {
        xSteps: 1, // Will start putting cell with 1 empty cell on left most
        ySteps: 5, //will put space of 5 rows,
        columns: [
            {title: "Nom"},
            {title: "Àlies"},
            {title: "Tipus"},
            {title: "Metres"},
            {title: "Temps d'ús"},
            {title: "Humitat"},
            {title: "Temperatura"},
            {title: "Qualitat de l'aire"},
        ],
        data: props.infoEspais.map(data => [
            {value: data.nombre},
            {value: data.alias},
            {value: data.tipo},
            {value: data.metros},
            {value: data.tiempo},
            {value: data.humitat},
            {value: data.temperatura},
            {value: data.aire}
          ])
    }
  ]

  console.log(DataSet)
    return (
    <div className='table-container'>
        <h4>Perfil</h4>
        <Table className='info-table' responsive>
            <thead>
                <tr>
                    <th>Nom</th>
                    <th>Tipus</th>
                    <th>Direcció</th>
                    <th>Ciutat</th>
                    <th>Província</th>
                    <th>CP</th>
                    <th>Humitat exterior</th>
                    <th>Temperatura exterior</th>
                    <th>Hora</th>
                </tr>
            </thead>
            <tbody>
                {props.info.lenght === 0 ? (
                    <tr>

                    </tr>
                ) : (
                    <>
                    {props.info.map(data => (
                        <tr key={data.id}>
                            <td>{data.nombre}</td>
                            <td>{data.tipo}</td>
                            <td>{data.direccion}</td>
                            <td>{data.ciudad}</td>
                            <td>{data.provincia}</td>
                            <td>{data.cod}</td>
                            <td>{data.humedad}</td>
                            <td>{data.temperatura}</td>
                            <td>{data.fechahora}</td>
                        </tr>
                    ))}
                    </>
                )}
            </tbody>
        </Table>
        <h4>Espais</h4>
        <Table className='espais-table' responsive>
            <thead>
                <tr>
                    <th>Nom</th>
                    <th>Àlies</th>
                    <th>Tipus</th>
                    <th>Metres</th>
                    <th>Temps d'ús</th>
                    <th>Humitat</th>
                    <th>Temperatura</th>
                    <th>Qualitat de l'aire</th>
                </tr>
            </thead>
            <tbody>
                {props.infoEspais.lenght === 0 ? (
                    <tr>

                    </tr>
                ) : (
                    <>
                    {props.infoEspais.map(data => (
                        <tr key={data.identificador}>
                            <td>{data.nombre}</td>
                            <td>{data.alias}</td>
                            <td>{data.tipo}</td>
                            <td>{data.metros}</td>
                            <td>{data.tiempo}</td>
                            <td>{data.humitat}</td>
                            <td>{data.temperatura}</td>
                            <td>{data.aire}</td>
                        </tr>
                    ))}
                    </>
                )}
            </tbody>
        </Table>
        {props.info.lenght !== 0 ? (
            <ExcelFile
            filename = "perfil-data"
            element = {<Button type='button' className="general-button" variant="primary">Descarregar</Button>}>
                <ExcelSheet dataSet={DataSet} name="perfil-data"/>
            </ExcelFile>
        ):null}
    </div>

  )
}
