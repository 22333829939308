import React, { useState, useEffect } from 'react'
import { Form, Button, FloatingLabel } from 'react-bootstrap'
import axios from 'axios'
import toast, { Toaster } from 'react-hot-toast';
import "../js/validatedPerfil.js"

export default function DadasExteriors(props) {
    const notify = (nombre) => toast.success('Perfil '+ nombre +' pujat');
    const notifyUpdate = (nombre) => toast.success(nombre +' actualizat');
    const [data, setData] = useState([]);
    const baseurl = "http://inventamosoerramos.com/api/perfil/";
    const validatedPerfil = () => {
        var valid = true;
        if (props.perfil.nombre === "") {
            valid = false
            alert("Camp nom obligatori");
        }
        if (props.perfil.tipo === "") {
            valid = false
            alert("Camp tipo obligatori");
        }
        if (props.perfil.tipo === "Tipus") {
            valid = false
            alert("Camp tipus obligatori");
        }
        if (props.perfil.direccion === "") {
            valid = false
            alert("Camp adreça obligatori");
        }
        if (props.perfil.provincia === "") {
            valid = false
            alert("Camp província obligatori");
        }
        if (props.perfil.ciudad === "") {
            valid = false
            alert("Camp ciutat obligatori");
        }
        if (props.perfil.humedad === "") {
            valid = false
            alert("Camp humitat obligatori");
        }
        if (props.perfil.temperatura === "") {
            valid = false
            alert("Camp temperatura obligatori");
        }
        return valid
    }
    const handlerChange = e=> {
        const {name, value} = e.target;
        props.setPerfil((prevState)=>({
            ...prevState,
            [name]:value
        }))
    }
    const peticionGet = async()=> {
        await axios.get(baseurl)
        .then(response=>{
            setData(response.data);
            var lastIndex = response.data.length - 1;
            var lastItem = response.data[lastIndex];
            var lastItemId = lastItem.id;
            props.setActual("" + lastItemId);
        })
        
    }
    const peticionPut = async(id)=> {        
        var f = new FormData();
        f.append("tipo", props.perfil.tipo);
        f.append("nombre", props.perfil.nombre);
        f.append("direccion", props.perfil.direccion);
        f.append("cod", props.perfil.cod);
        f.append("provincia", props.perfil.provincia);
        f.append("ciudad", props.perfil.ciudad);
        f.append("humedad", props.perfil.humedad);
        f.append("temperatura", props.perfil.temperatura);
        f.append("METHOD", "PUT");
        await axios.post(baseurl, f, {params: {id: id}})
        .then(response=>{
            notifyUpdate(props.perfil.nombre)
        })
    }
    const peticionPost = async()=> {  
        if (props.actual === "") {
            var f = new FormData();
            f.append("tipo", props.perfil.tipo);
            f.append("nombre", props.perfil.nombre);
            f.append("direccion", props.perfil.direccion);
            f.append("cod", props.perfil.cod);
            f.append("provincia", props.perfil.provincia);
            f.append("ciudad", props.perfil.ciudad);
            f.append("fechahora", props.perfil.fechahora);
            f.append("humedad", props.perfil.humedad);
            f.append("temperatura", props.perfil.temperatura);
            f.append("METHOD", "POST");
            await axios.post(baseurl, f)
            .then(response=>{
                setData(data.concat(response.data));
                peticionGet();
                notify(props.perfil.nombre);
            })
        }else {
            // quitar: En teoría no debería pasar por aquí
            alert("Hi ha un perfil seleccionat");
        }      
    }
    const handlerSave = () => {
        if (props.actual === "") {
            if ( validatedPerfil() ) {
                console.log("POST")
                peticionPost();
            }
        }else { // Hay un perfil seleccionado
            if ( validatedPerfil() ) {
                console.log("PUT")
                console.log(String(props.actual))
                peticionPut(String(props.actual));
            }
        }
    }
    useEffect(()=>{
    },[]);
    // console.log(props.actual);
    console.log(props.perfil);
    return (
         <div className="ubicacio-container">
        <Toaster />
            <h1 className="title">Quina és la humitat i la temperatura que hi ha l'exterior?</h1>
            <Form>
            <Form.Group className="dos-col" controlId="exampleForm.ControlTextarea1">
                <FloatingLabel
                            controlId="floatingInput"
                            label="Humitat (%)"
                            className="mb-3"
                            >
                            <Form.Control type="text" placeholder="Humitat" name="humedad" onChange={handlerChange} value={props.perfil.humedad}/>
                </FloatingLabel>
                <FloatingLabel
                            controlId="floatingInput"
                            label="Temperatura (°C)"
                            className="mb-3"
                            >
                            <Form.Control type="text" placeholder="Temperatura" name="temperatura" onChange={handlerChange} value={props.perfil.temperatura}/>
                </FloatingLabel>
            </Form.Group>
            </Form>
            <Button className="general-button" variant="primary" onClick={()=>handlerSave()}>Guardar</Button><br></br>
            <small>Continuar amb Sinergètik implica acceptar la <a href='https://www.arqbag.coop/politica-de-privadesa' target="_blank">política de privadesa</a></small>
        </div>
    )
}
