import React, { useState } from 'react'
import FormSelect from './FormSelectEspais'
import ListArea from './ListArea';
import '../App.css';

export default function EspaiSelector(props) {
    const [input,setInput] = useState("");
    const [espais,setEspais] = useState([]);

    return (
        <div>
            <div className="add-container">
                <FormSelect tipo={props.tipo} setTipo={props.setTipo} type={props.type} actual={props.actual} text={props.text} input={input} setInput={setInput} espais={espais} setEspais={setEspais} espacios={props.espacios} setEspacios={props.setEspacios}/>
                <ListArea actual={props.actual} tipo={props.tipo} setTipo={props.setTipo} type={props.type} espais={espais} setEspais={setEspais} espacios={props.espacios} setEspacios={props.setEspacios}/>     
            </div>
        </div>
    )
}
