import React, { useState, useEffect } from 'react'
import axios from 'axios'
import OutputEspai from '../components/OutputEspai'
import { Button } from 'react-bootstrap'

export default function Semaforperespais(props) {
    const [data, setData] = useState([]);
    const baseurl = "http://inventamosoerramos.com/api/espais/";
    const perfilApi = "http://inventamosoerramos.com/api/perfil/";
    
    const peticionGetEspais = async()=> {
        await axios.get(baseurl+"?perfil="+props.actual)
        .then(response=>{
            setData(response.data);
            props.setInfoEspais(response.data);
        })
    }
    const peticionGetInfo = async()=> {
        await axios.get(perfilApi+"?id="+props.actual)
        .then(response=>{
            props.setInfo(response.data);
        })
    }
    useEffect(()=>{
        if (props.actual != "") {
            peticionGetEspais();
            peticionGetInfo();
        }else {
            alert("no hay perfil seleccionado")
        }
    },[]);
    return (
        <div>
              <h1 className="title">Semàfor del confort dels espais analitzats!</h1>
              <div className="outputs-all-container">
                <p className='header-table'>
                    <small>Espai</small>
                    <small>Alias</small>
                    <small className='header-margin-left'>Superfície</small>
                    <small className='header-margin-left'>Ús</small>
                    <small>Aire</small>
                    <small>Humitat</small>
                    <small>Temperatura</small>
                </p>
                {data.map(espacio => (
                    <OutputEspai metros={espacio.metros} tiempo={espacio.tiempo} alias={espacio.alias} text={espacio.nombre} temperatura={espacio.temperatura} humedad={espacio.humitat} aire={espacio.aire} color="red-output"></OutputEspai>
                ))}
              </div>
              <Button className="general-button" variant="primary" href='#/export'>Exportar taules</Button>
              <div className='text-recomendations'>
                  <div className='paraph'>
                    <h3><div className='hightlight'>Quins espais requereixen la nostra atenció?</div></h3>
                    <p>
                    Podem millorar la seva organització tenint en compte, per exemple, l'aprofitament de llocs poc utilitzats?
                    I si ens fixéssim en altres espais de l'entorn amb millors condicions de confort?
                    </p>
                    <p>
                    L'orientació, la radiació solar directa, els vents dominants, les obertures per a l’entrada de la llum,
                    els sistemes de protecció solar per l’estiu, l’aplicació de materials saludables poden condicionar el confort dels espais!  
                    </p>
                  </div>
                  <div className='copy'>
                    <h3><div className='hightlight'>Experimentem!</div>
                    Siguem usuàries actives dels espais que vivim!<br/>
                    Millorar les seves condicions també depèn de nosaltres!!</h3>
                  </div>
              </div>

        </div>
    )
}
