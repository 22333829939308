import React, { useState } from 'react'
import { Form } from 'react-bootstrap'

export default function MesureAire(props) {
    const valorAireEnEspacios =  () =>{
        const espacioActual = props.espacios.filter((el) => el.id === props.identificador)
        if ( espacioActual[0].aire === 0 ) {
            return ""
        }else {
            return espacioActual[0].aire
        }
    }
    const [rangeValue, setRangeValue] = useState(valorAireEnEspacios());
    // console.log(props.espacios)
    return (
        <>
                <div className="mesure-container">
                <small class="metros-small-label">{props.metros} m²</small>
                    <h3>{props.text}</h3>
                    <div className="mesure-result-container"><strong>{rangeValue}</strong><small> ppm</small></div>
                    <Form>
                        <Form.Label>Qualitat de l'aire</Form.Label>
                        <Form.Range 
                            id="1" 
                            min="400" 
                            max="2000" 
                            step="50"
                            onChange={(e) => {
                                setRangeValue(e.target.value);
                                props.espacios.find((o,i)=>{
                                    if(o.id === props.identificador) {
                                        props.espacios[i].aire = e.target.value;
                                    }
                                })
                            }}
                        />
                    </Form>
                </div>
        </>
    )
}
