import React, { useState, useEffect } from 'react'
import axios from 'axios'
import "../css/output.css"

export default function OutputEspai(props) {
    const [color, setColor] = useState("grey");
    const ponderarAire = (valor) => {
        if (valor >= 400 && valor <= 800) {return "verde"}
        if (valor > 800 && valor <= 900) {return "amarillo"}
        if (valor > 900) {return "rojo"}
    }
    const ponderarHumedad = (valor) => {
        if (valor > 40 && valor <= 60) {return "verde"}
        if (valor > 30 && valor <= 40) {return "amarillo"}
        if (valor > 60 && valor <= 70) {return "amarillo"}
        if (valor > 70) {return "rojo"}
        if (valor < 30) {return "rojo"}
    }
    const ponderarTemperatura = (valor) => {
        if (valor >= 21 && valor <= 25) {return "verde"}
        if (valor > 19 && valor < 21) {return "amarillo"}
        if (valor > 25 && valor <= 27) {return "amarillo"}
        if (valor > 27) {return "rojo"}
        if (valor < 19) {return "rojo"}
    }
    const calculSemafor = (aire, humedad, temperatura) => {
        var counterVerde = 0;
        var counterAmarillo = 0;
        var counterRojo = 0;
        // console.log(aire)
        // console.log(humedad)
        // console.log(temperatura)
        console.log("el aire es: "+ponderarAire(aire));
        console.log("la humedad es: "+ponderarHumedad(humedad));
        console.log("la temperatura es: "+ponderarTemperatura(temperatura));
        var colorAire = ponderarAire(aire);
            if (colorAire === "verde") { counterVerde++ }
            if (colorAire === "amarillo") { counterAmarillo++ }
            if (colorAire === "rojo") { counterRojo++ }
        var colorHumedad = ponderarHumedad(humedad);
            if (colorHumedad === "verde") { counterVerde++ }
            if (colorHumedad === "amarillo") { counterAmarillo++ }
            if (colorHumedad === "rojo") { counterRojo++ }
        var colorTemperatura = ponderarTemperatura(temperatura);
            if (colorTemperatura === "verde") { counterVerde++ }
            if (colorTemperatura === "amarillo") { counterAmarillo++ }
            if (colorTemperatura === "rojo") { counterRojo++ }
        // console.log("conunter verde: "+counterVerde)
        // console.log("counter amarillo: "+counterAmarillo)
        // console.log("counter rojo: "+counterRojo)
       if (counterVerde > counterAmarillo || counterVerde > counterRojo) {
            return "green"
        }else if (counterRojo > counterAmarillo || counterRojo > counterVerde ) {
            return "red"
        }else {
            return "yellow"
        }
    }
    useEffect(()=>{
        if (props.actual != "") {
            console.log("------hay perfil")
            console.log(calculSemafor(props.aire, props.humedad, props.temperatura))
            setColor(calculSemafor(props.aire, props.humedad, props.temperatura));
        }else {
            alert("no hay perfil seleccionado")
        }
    },[]);
    return (
        <div>
            <div className={"output-container"}>
                <p className='output-title'>{props.text}</p>
                <p className='output-alias'>{props.alias}</p>
                <p className='output-metres'><strong>{props.metros}</strong> m²</p>
                <p className='output-metres'><strong> {props.tiempo}</strong> h/d</p>
                <p className={''+ponderarAire(props.aire)}>{props.aire} PPM</p>
                <p className={''+ponderarHumedad(props.humedad)}>{props.humedad} %</p>
                <p className={''+ponderarTemperatura(props.temperatura)}>{props.temperatura} °C</p>
            </div>
        </div>
    )
}
