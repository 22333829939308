import React from 'react'
import logo from "../img/arqbag-logo.jpg"
import som from "../img/som-energia-logo.jpg"
import coop from "../img/coop-57-logo.jpg"
import fundacion from "../img/fundacion-coop-57.png"

export default function Footer() {
  return (
    <div className='footer'>
      <img className='footer-logo-arqbag' src={logo} alt="arqbag"/>
      <small><strong>arqbag</strong>  |  cooperativa d'arquitectura</small>
      <small className='align-right-footer'><strong>/</strong></small>
      <small className='align-right-footer'><strong>AEDO SOCIAL FILMS</strong></small>
      <small className='align-right-footer'><strong>/</strong></small>
      <small className='align-right-footer'><strong>luis soria</strong></small>
      <small className='align-right-footer'><strong>/</strong></small>
      <small className='align-right-footer'><strong><a style={{textDecoration:"none",color:"#2c3133"}} href='http://www.arqbag.coop/sinergetik' target="_blank">www.arqbag.coop/sinergetik</a></strong></small>
      <div className='footer-right'>
        <small>Amb el suport de: </small>
        <img src={som} alt="som-energia"></img>
        <img src={coop} alt="coop-57"></img>
        <img src={fundacion} alt="fundacion-coop-57"></img>
      </div>
    </div>
  )
}
