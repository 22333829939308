import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import deleteIcon from '../img/delete_2.png'
import axios from 'axios'
import toast, { Toaster } from 'react-hot-toast';



export default function Espai({text, espai, espais, setEspais, espacios, setEspacios, type, actual}) {
    const valorMetrosEnEspacios =  () =>{
        const espacioActual = espacios.filter((el) => el.id === espai)
        if ( espacioActual[0].metros === 0 ) {
            return ""
        }else {
            return espacioActual[0].metros
        }
    }
    const valorTiempoEnEspacios =  () =>{
        const espacioActual = espacios.filter((el) => el.id === espai)
        if ( espacioActual[0].tiempo === 0 ) {
            return ""
        }else {
            return espacioActual[0].tiempo
        }
    }
    const valorAliasEnEspacios =  () =>{
        const espacioActual = espacios.filter((el) => el.id === espai)
        if ( espacioActual[0].alias === "" ) {
            return ""
        }else {
            return espacioActual[0].alias
        }
    }
    const [metroEspacios, setMetroEspacio] = useState("");
    const [data, setData] = useState([]);
    const [metros, setMetros] = useState(valorMetrosEnEspacios());
    const [tiempo, setTiempo] = useState(valorTiempoEnEspacios());
    const [alias, setAlias] = useState(valorAliasEnEspacios());
    const baseurl = "http://inventamosoerramos.com/api/espais/";
    console.log(espai)
    console.log(actual)

    const peticionDelete = async(id)=> {        
        var f = new FormData();
        f.append("METHOD", "DELETE");
        await axios.post(baseurl, f, {params: {identificador: id}})
        .then(response=>{
            console.log("Espais esborrat!");
        })
    }
    const peticionGetEspais = async()=> {
        console.log(actual)
        await axios.get(baseurl+"?perfil="+actual)
        .then(response=>{
            // setData(response.data);
            console.log(response.data)
        })
    }
    const handlerChange = e=> {
        const {name, value} = e.target;
        if (name==="metros") {
            setMetros(value);
        }else if (name==="tiempo") {
            setTiempo(value);
        }else {
            setAlias(value);
        }
    }
    const deleteHandler = async() => {
        setEspacios(espacios.filter((el) => el.id !== espai));
    }
    const setValues = () => {
        espacios.find((o,i)=>{
            if(o.id === espai) {
                    espacios[i].metros = metros;
                    espacios[i].tiempo = tiempo;
                    espacios[i].alias = alias;
            }
        })
    }
    setValues();
    console.log(espacios)
    return (
        <div className="item">
            <Form type={type}>
                <div className='espai-title'>{text}</div>
                <div className='espai-input-container'>
                    <Form.Control size="sm" type="text" placeholder="Àlies" value={alias} name="alias" onChange={handlerChange}/>
                    <Form.Control size="sm" type="text" placeholder="m²" value={metros} name="metros" onChange={handlerChange}/>
                    <Form.Control size="sm" type="text" placeholder="h/dia" value={tiempo} name="tiempo" onChange={handlerChange}/>
                    <button onClick = {deleteHandler}>
                        <img className="icon-select" src={deleteIcon} alt="icon"/>
                    </button>
                </div>
            </Form>

        </div>
    )
}
